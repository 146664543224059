import aboutArrow from "assets/images/about-arrow.png";
import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const CooperationItem = ({ image, href, title }) => {
  return (
    <a target="_blank" href={href} className={css.link} rel="noreferrer">
      <img
        src={image}
        alt="cooperation"
        title="Link image"
        className={css.linkImage}
      />
      <div className={css.linkDecoration}>
        <img
          src={aboutArrow}
          alt="arrow decoration"
          title="Arrow decoration"
          className={css.arrowDecoration}
        />
      </div>
      <Text type="white" className={css.linkTitle} bold secondaryFamily>
        {title}
      </Text>
    </a>
  );
};
