import cn from "classnames";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";

import logo from "assets/images/header-logo.png";
import { Text } from "shared/components/Text";
import { Spinner } from "shared/components/Spinner";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { Toast } from "features/MainPage/Toast";
import { Button } from "shared/components/Button";
import { splitArrayToChunks } from "shared/utils";
import locationVideo from "assets/videos/pxl-location-video.mp4";
import locationVerticalVideo from "assets/videos/pxl-location-vertical-video.mp4";
import icon_pixelbox from "assets/images/icon-pixel-box.png";

import css from "./styles.module.scss";
import { getLocations } from "./locations";

const LOCATION_OPTIONS_CHUNK_SIZE = 3;

const checkIfCityUrlChosenTimestampIsOlderThanFiveMinutes = (
  cityUrlChosenTimestamp
) => {
  return (
    cityUrlChosenTimestamp &&
    Date.now() - cityUrlChosenTimestamp > 60 * 5 * 1000
  );
};

const isIos = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "MacIntel",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const LocationsPage = ({ isSuccessInUrl }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [toastText, setToastText] = useState("");
  const [requestFailed, setRequestFailed] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const locations = useMemo(() => {
    const rawLocations = getLocations();
    const parsedLocations = splitArrayToChunks({
      array: Object.values(rawLocations).filter(
        (el) => el.siteUrl !== "/slask"
      ),
      chunkSize: LOCATION_OPTIONS_CHUNK_SIZE,
    });

    return parsedLocations;
  }, [i18n.language]);

  const cityUrlSaved = useMemo(() => {
    return {
      cityUrl: window.localStorage.getItem("cityUrl"),
      cityUrlChosenTimestamp: window.localStorage.getItem(
        "cityUrlChosenTimestamp"
      ),
    };
  }, []);

  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  useEffect(() => {
    if (
      isSuccessInUrl &&
      (!cityUrlSaved.cityUrl ||
        !checkIfCityUrlChosenTimestampIsOlderThanFiveMinutes(
          cityUrlSaved.cityUrlChosenTimestamp
        ))
    ) {
      setToastText(t("shared.success"));
    }
  }, [isSuccessInUrl, cityUrlSaved]);

  useEffect(() => {
    if (
      cityUrlSaved.cityUrl &&
      checkIfCityUrlChosenTimestampIsOlderThanFiveMinutes(
        cityUrlSaved.cityUrlChosenTimestamp
      )
    ) {
      return;
    }
    const bookingId = searchParams.get("booking_id");
    const personId = searchParams.get("person_id");

    const cancelReservation = async () => {
      const response = await fetch(
        `https://xl.plan4u.pl/pixel/xl/api/v1/o_cancelBooking/${bookingId}/${personId}`
      );
      const json = await response.json();
      if (json.status === "success") {
        setToastText(t("shared.cancelled"));
        setRequestFailed(false);
      } else {
        setToastText(t("shared.cancelError"));
        setRequestFailed(true);
      }
    };
    if (bookingId && personId) {
      cancelReservation();
    }
  }, [searchParams, cityUrlSaved]);

  useEffect(() => {
    document.title = "Pixel XL";
    if (
      cityUrlSaved.cityUrl &&
      checkIfCityUrlChosenTimestampIsOlderThanFiveMinutes(
        cityUrlSaved.cityUrlChosenTimestamp
      )
    ) {
      const bookingId = searchParams.get("booking_id");
      const personId = searchParams.get("person_id");

      let navigateUrl = cityUrlSaved.cityUrl;
      if (bookingId && personId) {
        navigateUrl += `/cancel?booking_id=${bookingId}&person_id=${personId}`;
      } else if (isSuccessInUrl) {
        navigateUrl += "/success";
      }
      navigate(navigateUrl);
    }
  }, [navigate, cityUrlSaved, isSuccessInUrl, searchParams]);

  return (
    <div className={css.LocationsPage}>
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          borderColor="blue"
          className={css.languageChooser}
          shouldScrollUp={isMobile}
        />
      </div>
      <img src={logo} alt="pixel xl logo" className={css.headerLogo} />

      {toastText && <Toast text={toastText} failed={requestFailed} />}

      <div className={cn(css.videoBackground, { [css.hide]: !isVideoLoading })}>
        {!isMobile && isVideoLoading && <Spinner />}
      </div>
      <div className={css.iframeContainer}>
        <ReactPlayer
          playsinline={true}
          controls={false}
          playing={true}
          muted={true}
          loop={true}
          onPlay={() => {
            setIsVideoLoading(false);
          }}
          className={cn(css.iframe, {
            [css.iframeWithMargin]: isMobile || isIos,
          })}
          width="100%"
          height="100%"
          url={
            isMobile
              ? "https://www.youtube.com/embed/BuEFktKdppM?si=HXOTLs6ZmxEEmGQy"
              : isIos
              ? "https://www.youtube.com/embed/YvkSxfT-znI?si=vnW8q5sGIJHwR16H"
              : locationVideo
          }
          type="video/mp4"
        />

        <div className={css.iframeBlend} />
      </div>
      <div className={css.buttonsContainer}>
        <Text type="white" className={css.prompt} bold secondaryFamily>
          {t("locationsPage.exploreNewDimension")}
        </Text>
        {locations.map((locationRow, i) => (
          <div className={css.locationRow} key={`row ${i}`}>
            {locationRow.map((location, j) => (
              <div
                className={css.buttonContainer}
                key={`locationOption ${i * j + j}`}
              >
                <Button
                  text={location.title}
                  onClick={() => {
                    window.localStorage.setItem("cityUrl", location.siteUrl);
                    window.localStorage.setItem(
                      "cityUrlChosenTimestamp",
                      Date.now()
                    );
                    navigate(location.siteUrl);
                  }}
                  className={css.button}
                />
                {location.label && (
                  <div className={css.label}>
                    <Text type="whiteBordered" className={css.labelText} bold>
                      {location.label}
                    </Text>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
