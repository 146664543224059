import cn from "classnames";

import css from "./styles.module.scss";

export const Spinner = ({ type }) => {
  return (
    <div
      className={cn(css.spinner, {
        [css.smallSpinner]: type === "small",
        [css.bigSpinner]: type === "big",
      })}
    />
  );
};
