import cn from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import { useMutationObservable } from "shared/hooks/useMutationObservable";
import arrow from "assets/images/arrow-down.png";

import css from "./styles.module.scss";
import { Text } from "../Text";

const HEIGHT_OF_OPTION = 22;
const LARGE_SCREEN_HEIGHT_OF_OPTION = 36;

export const Select = ({
  options,
  autoComplete,
  value,
  setValue,
  focusedValue,
  placeholder,
  isWrong,
}) => {
  const isLargeScreen = window.matchMedia("(max-width: 2160px)");
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const optionsContainerRef = useRef();
  const fakeInputRef = useRef();
  const buttonRef = useRef();

  const onInputMutation = useCallback(
    (mutation) => {
      const mutatedValue = parseInt(mutation[0].target.value);
      if (!isNaN(mutatedValue)) {
        setValue(mutatedValue);
        setInputValue("");
      }
    },
    [setValue]
  );

  // we need to use MutationObserver, because autoComplete input fill doesn't trigger input events (onChange, onInput etc.)
  useMutationObservable(fakeInputRef.current, onInputMutation);

  useEffect(() => {
    // scrolling to have value or focusedValue on top
    if (areOptionsVisible) {
      const valueToScrollTo = value || focusedValue;

      if (valueToScrollTo) {
        const valueIndexOfOptions = options.findIndex(
          (el) => el.value === valueToScrollTo
        );

        if (valueIndexOfOptions > 0) {
          optionsContainerRef.current.scrollTop =
            valueIndexOfOptions *
            (isLargeScreen.matches
              ? LARGE_SCREEN_HEIGHT_OF_OPTION
              : HEIGHT_OF_OPTION);
        }
      } else {
        optionsContainerRef.current.scrollTop = 0;
      }
    }
  }, [areOptionsVisible, options, optionsContainerRef, focusedValue, value]);

  return (
    <div
      className={css.Select}
      onBlur={() => {
        setAreOptionsVisible(false);
      }}
    >
      <input
        ref={fakeInputRef}
        type="text"
        className={css.fakeInput}
        autoComplete={autoComplete}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <button
        ref={buttonRef}
        // onMouseDown executes before onBlur (the one set on css.Select div), in contrary to onClick
        onMouseDown={(e) => {
          setAreOptionsVisible((value) => !value);
          // safari doesn't focus buttons on click, and we need focus for the onBlur to work
          // other browsers do, but we dont need to do this conditionally as doing it twice doesn't cost much
          buttonRef.current.focus();

          // preventation of blurring buttonRef on initial click
          e.preventDefault();
        }}
        className={css.openButton}
      >
        <div
          className={cn(css.valueContainer, {
            [css.wrongValueContainer]: isWrong,
          })}
        >
          <Text className={css.value}>
            {options.find((el) => el.value === value)
              ? options.find((el) => el.value === value).label
              : placeholder || ""}
          </Text>
        </div>
        <img
          src={arrow}
          className={css.arrow}
          alt="dropdown arrow"
          title="Dropdown arrow"
        />
      </button>
      {areOptionsVisible && (
        <div className={css.optionsContainer} ref={optionsContainerRef}>
          {options.map((el) => (
            <button
              style={{
                minHeight:
                  (isLargeScreen
                    ? LARGE_SCREEN_HEIGHT_OF_OPTION
                    : HEIGHT_OF_OPTION) + "px",
              }}
              key={el.value}
              // onMouseDown executes before onBlur (the one set on css.Select div), in contrary to onClick
              onMouseDown={() => {
                setValue(el.value);
              }}
              className={cn(css.option, {
                [css.selectedOption]: value && el.value === value,
              })}
            >
              <Text className={css.optionText} type="black">
                {el.label}
              </Text>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
