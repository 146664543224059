import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import { TextInput } from "shared/components/TextInput";

import css from "./styles.module.scss";
import { Button } from "shared/components/Button";
import { signUpForClasses } from "../classesApi";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const emailPattern =
  /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const ClassesPageForm = ({
  passedRef,
  setIsSubmitLoading,
  catalogHref,
  statueHref,
}) => {
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submitClassesForm");
    return token;
  }, [executeRecaptcha]);

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [connectionError, setConnectionError] = useState("");

  const [wasSuccessful, setWasSuccessful] = useState(false);

  const onSubmit = async () => {
    setConnectionError("");
    setFirstNameError("");
    setSurnameError("");
    setAgeError("");
    setEmailError("");
    setPhoneError("");
    let wrongSubmit = false;

    const emailTrimmed = email.trim();
    if (!emailTrimmed) {
      setEmailError(t("form.errors.emptyEmail"));
      wrongSubmit = true;
    } else if (!emailPattern.test(emailTrimmed)) {
      setEmailError(t("form.errors.incorrectEmail"));
      wrongSubmit = true;
    } else if (emailTrimmed.length > 64) {
      setEmailError(t("form.errors.emailTooLong"));
      wrongSubmit = true;
    }

    if (!firstName) {
      setFirstNameError(t("form.errors.emptyName"));
      wrongSubmit = true;
    } else if (firstName.length > 64) {
      setFirstNameError(t("form.errors.nameTooLong"));
      wrongSubmit = true;
    }

    if (!surname) {
      setSurnameError(t("form.errors.emptySurname"));
      wrongSubmit = true;
    } else if (surname.length > 64) {
      setSurnameError(t("form.errors.surnameTooLong"));
      wrongSubmit = true;
    }

    // input type number parses characters to empty string
    if (!age || isNaN(parseInt(age))) {
      setAgeError(t("form.errors.incorrectAge"));
      wrongSubmit = true;
    }

    if (!phone) {
      setPhoneError(t("form.errors.emptyPhone"));
      wrongSubmit = true;
    } else if (phone.length < 7) {
      setPhoneError(t("form.errors.incorrectPhone"));
      wrongSubmit = true;
    }

    if (wrongSubmit) {
      return;
    }
    setIsSubmitLoading(true);
    try {
      const token = await handleReCaptchaVerify();

      const response = await signUpForClasses({
        name: firstName,
        surname,
        age,
        email,
        phone,
        token,
        referralCode,
      });
      setWasSuccessful(true);
      setFirstName("");
      setSurname("");
      setAge("");
      setEmail("");
      setPhone("");
      setReferralCode("");
    } catch (e) {
      console.log(e);
      setConnectionError(t("form.errors.connectionError"));
    }
    setIsSubmitLoading(false);
  };

  return (
    <div className={css.contactSection} ref={passedRef}>
      {!wasSuccessful ? (
        <>
          <Text
            type="white"
            bold
            className={css.contactSectionTitle}
            alignCenter
            secondaryFamily
          >
            {t("classesPage.contactSectionTitle")}
          </Text>
          <form className={css.form}>
            <div className={css.formLineContainer}>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionFirstName")}
                  value={firstName}
                  setValue={setFirstName}
                  mode="classes"
                  error={firstNameError}
                />
              </div>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionLastName")}
                  value={surname}
                  setValue={setSurname}
                  mode="classes"
                  error={surnameError}
                />
              </div>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionAge")}
                  value={age}
                  setValue={setAge}
                  mode="classes"
                  error={ageError}
                  type="number"
                />
              </div>
            </div>
            <div className={css.formLineContainer}>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionMail")}
                  value={email}
                  setValue={setEmail}
                  type="email"
                  mode="classes"
                  error={emailError}
                />
              </div>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionPhone")}
                  value={phone}
                  setValue={setPhone}
                  type="tel"
                  autoComplete="tel"
                  mode="classes"
                  error={phoneError}
                />
              </div>
              <div className={css.formTextInputContainer}>
                <TextInput
                  placeholder={t("classesPage.contactSectionReferralCode")}
                  value={referralCode}
                  setValue={setReferralCode}
                  mode="classes"
                />
              </div>
            </div>
          </form>
          {connectionError && (
            <Text type="red" className={css.connectionErrorText} alignCenter>
              {connectionError}
            </Text>
          )}
          <Text
            type="white"
            className={css.contactSectionFooterText}
            alignCenter
          >
            {t("classesPage.contactSectionFooterText")}
          </Text>
          <Button
            text={t("classesPage.contactButton")}
            className={css.submitButton}
            textClassname={css.submitButtonText}
            onClick={onSubmit}
          />
        </>
      ) : (
        <div className={css.thankYouContainer}>
          <Text
            type="pink"
            className={css.thankYouTextBigger}
            alignCenter
            bold
            secondaryFamily
          >
            {t("classesPage.contactedThankYou")}
          </Text>
          <Text
            type="white"
            className={css.thankYouText}
            alignCenter
            secondaryFamily
          >
            {t("classesPage.contactedPrompt")}
          </Text>
          <Button
            text={t("classesPage.contactAgainButton")}
            className={css.submitButton}
            textClassname={css.submitButtonText}
            onClick={() => setWasSuccessful(false)}
          />
        </div>
      )}
      <a className={css.link} href={catalogHref} download>
        <Text className={css.linkText} type="white" bold>
          {t("classesPage.downloadCatalog")}
        </Text>
      </a>
      <a className={css.link} href={statueHref} download>
        <Text className={css.linkText} type="white" bold>
          {t("classesPage.downloadStatue")}
        </Text>
      </a>
    </div>
  );
};
