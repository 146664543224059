import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LocationPin } from "assets/icons/location-pin.svg";
import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const ResetLocationButton = ({
  cityName,
  className,
  textClassName,
  locationPinClassName,
}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        window.localStorage.removeItem("cityUrl");
        window.localStorage.removeItem("cityUrlChosenTimestamp");
        navigate("/");
      }}
      className={cn(css.button, className)}
    >
      <LocationPin className={cn(css.locationPin, locationPinClassName)} />
      <Text type="white" className={cn(css.text, textClassName)}>
        {cityName}
      </Text>
    </button>
  );
};
