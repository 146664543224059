import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import { IconLink } from "shared/components/IconLink";
import footerLogo from "assets/images/footer-logo.png";
import facebookIcon from "assets/icons/facebook.png";
import facebookHoverIcon from "assets/icons/facebook-hover.png";
import instagramIcon from "assets/icons/instagram.png";
import instagramHoverIcon from "assets/icons/instagram-hover.png";
import tiktokIcon from "assets/icons/tiktok.png";
import tiktokHoverIcon from "assets/icons/tiktok-hover.png";
import privacyPolicy from "assets/documents/privacy-policy-pixelxl.pdf";
import statue from "assets/documents/statue.pdf";
import arrowUp from "assets/images/arrow-up.png";
import arrowUpHover from "assets/images/arrow-up-hover.png";
import decorativeSquare from "assets/images/decorative-square.png";

import css from "./styles.module.scss";

export const Footer = ({ scrollCallback }) => {
  const { t } = useTranslation();

  return (
    <div className={css.Footer}>
      <div className={css.internalFooter}>
        <div className={css.mobileArrowContainer}>
          <button onClick={scrollCallback}>
            <img
              title="Arrow up"
              src={arrowUp}
              className={css.arrow}
              alt="arrow up"
              onMouseOver={(e) => {
                e.currentTarget.src = arrowUpHover;
              }}
              onMouseOut={(e) => {
                e.currentTarget.src = arrowUp;
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
                e.currentTarget.src = arrowUpHover;
              }}
              onTouchCancel={(e) => {
                e.stopPropagation();
                e.currentTarget.src = arrowUp;
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.currentTarget.src = arrowUp;
              }}
            />
          </button>
          <img
            title="Decoration square"
            src={decorativeSquare}
            alt="decoration square"
            className={css.smallSquare}
          />
          <img
            title="Decoration square"
            src={decorativeSquare}
            alt="decoration square"
            className={css.mediumSquare}
          />
        </div>
        <div className={css.leftColumn}>
          <img src={footerLogo} alt="footer logo" className={css.logo} />
          <Text type="purple" className={css.text}>
            Copyright © 2022-2024 pixelXL.com.{"\n"}All Rights Reserved
          </Text>
        </div>
        <div className={css.rightColumn}>
          <div className={css.policiesContainer}>
            <a href={privacyPolicy} target="_blank">
              <Text type="white" className={css.link}>
                {t("footer.privacyPolicy")}
              </Text>
            </a>
            <a href={statue} target="_blank">
              <Text type="white" className={css.link}>
                {t("footer.statue")}
              </Text>
            </a>
          </div>
          <div className={css.iconsContainer}>
            <IconLink
              href="https://www.facebook.com/pixelxlpoland"
              icon={facebookIcon}
              iconHovered={facebookHoverIcon}
              newTab
              className={css.icon}
            />
            <IconLink
              href="https://www.instagram.com/pixelxlpoland/"
              icon={instagramIcon}
              iconHovered={instagramHoverIcon}
              newTab
              className={css.icon}
            />
            <IconLink
              href="https://www.tiktok.com/@pixelxlpoland"
              icon={tiktokIcon}
              iconHovered={tiktokHoverIcon}
              newTab
              className={css.icon}
            />
          </div>
          <div className={css.arrowContainer}>
            <button onClick={scrollCallback}>
              <img
                src={arrowUp}
                className={css.arrow}
                alt="arrow up"
                onMouseOver={(e) => {
                  e.currentTarget.src = arrowUpHover;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.src = arrowUp;
                }}
              />
            </button>
            <img
              title="Decoration square"
              src={decorativeSquare}
              alt="decoration square"
              className={css.smallSquare}
            />
            <img
              title="Decoration square"
              src={decorativeSquare}
              alt="decoration square"
              className={css.mediumSquare}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
