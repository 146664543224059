import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getLocations } from "features/LocationsPage/locations";
import { Footer } from "shared/components/Footer";
import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { LoadingOverlay } from "shared/components/LoadingOverlay";
import banner from "assets/images/classes-page-banner.png";
import hosts from "assets/images/classes-page-hosts.png";
import phoneIcon from "assets/icons/telephone.png";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Header } from "./Header";
import { ClassesPageForm } from "./ClassesPageForm";
import css from "./styles.module.scss";

export const ClassesPage = ({ city }) => {
  const { t } = useTranslation();
  const locations = getLocations();

  const formRef = useRef();
  const topRef = useRef();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const footerScrollCallback = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    topRef.current.focus();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={css.ClassesPage}>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {isSubmitLoading && <LoadingOverlay />}
      <img
        src={banner}
        alt="Banner image"
        className={css.banner}
        ref={topRef}
      />
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <Header city={city} cityName={locations[city]?.title || ""} />
      <div className={css.content}>
        <Text
          type="white"
          className={css.title}
          bold
          alignCenter
          secondaryFamily
        >
          {t("classesPage.title")}
        </Text>
        <Text type="lightBlue" className={css.subtitle} bold alignCenter>
          {t("classesPage.subtitle")}
        </Text>
        <Button
          className={css.registrationButton}
          textClassname={css.registrationButtonText}
          text={t("classesPage.registrationButtonText")}
          onClick={() => {
            formRef.current.scrollIntoView({ behavior: "smooth" });
            formRef.current.focus();
          }}
        />
        <div className={css.informationSection}>
          <Text
            type="white"
            bold
            alignCenter
            secondaryFamily
            className={css.informationSectionTitle}
          >
            {t("classesPage.informationSectionTitle")}
          </Text>
          <Text
            type="white"
            alignCenter
            className={css.informationSectionContent}
          >
            {t("classesPage.informationSectionContent")}
          </Text>
        </div>
        <div className={css.datesContainer}>
          <div className={css.dateRow}>
            <Text type="white" className={css.dateText} alignCenter bold>
              {t("classesPage.lowerAge")}
            </Text>
            <Text type="white" className={css.dateText} alignCenter bold>
              {t("classesPage.higherAge")}
            </Text>
          </div>
          <div className={css.dateRow}>
            <Text type="lightBlue" className={css.dateText} alignCenter bold>
              {t("classesPage.youngerDates")}
            </Text>
            <Text type="lightBlue" className={css.dateText} alignCenter bold>
              {t("classesPage.olderDates")}
            </Text>
          </div>
        </div>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_CLASSES_RECAPTCHA_KEY}
          container={{
            element: "recaptchaContainer",
            parameters: {
              badge: "bottomleft",
              size: "invisible",
            },
          }}
        >
          <ClassesPageForm
            passedRef={formRef}
            setIsSubmitLoading={setIsSubmitLoading}
            catalogHref={locations[city].classesCatalog}
            statueHref={locations[city].classesStatue}
          />
        </GoogleReCaptchaProvider>
        <div className={css.footer}>
          <img src={hosts} alt="two guys" className={css.hostsImage} />
          <Text type="white" className={css.footerContent} alignCenter>
            {t("classesPage.footerContent")}
          </Text>
          <div className={css.phoneNumberSection}>
            <img src={phoneIcon} alt="phone icon" className={css.phoneIcon} />
            <a href="tel:71-726-32-34">
              <Text
                type="white"
                className={css.phoneNumber}
                secondaryFamily
                bold
              >
                +48 71 726 32 34
              </Text>
            </a>
          </div>
        </div>
      </div>
      <Footer scrollCallback={footerScrollCallback} />
    </div>
  );
};
