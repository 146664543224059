import css from "./styles.module.scss";

export const IconLink = ({ href, icon, iconHovered, newTab, className }) => {
	return (
		<a
			href={href}
			target={newTab ? "_blank" : "_self"}
			className={css.IconLink}
		>
			<img
				src={icon}
				alt="icon link"
				title="Icon link"
				className={className}
				onMouseOver={(e) => {
					e.currentTarget.src = iconHovered;
				}}
				onMouseOut={(e) => {
					e.currentTarget.src = icon;
				}}
			/>
		</a>
	);
};
