import {
	useRef,
	useState,
	useEffect,
	Children,
	isValidElement,
	cloneElement,
} from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const GoogleMaps = ({ options, markerOptions }) => {
	const render = (status) => {
		switch (status) {
			case Status.LOADING:
				return <GoogleMapsLoading />;
			case Status.FAILURE:
				return <GoogleMapsLoading />;
			case Status.SUCCESS:
				return (
					<GoogleMapsSuccess options={options}>
						<Marker {...markerOptions} />
					</GoogleMapsSuccess>
				);
		}
	};

	return (
		<Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render} />
	);
};

const GoogleMapsLoading = () => {
	return (
		<div className={css.googleMaps}>
			<div className={css.spinner} />
		</div>
	);
};

const GoogleMapsSuccess = ({ options, children }) => {
	const ref = useRef(null);
	const [map, setMap] = useState();

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	return (
		<div className={css.googleMaps}>
			<div ref={ref} style={{ height: "100%", width: "100%" }} />
			{Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement(child, { map });
				}
			})}
		</div>
	);
};

const Marker = (options) => {
	const [marker, setMarker] = useState();

	useEffect(() => {
		if (!marker) {
			setMarker(new window.google.maps.Marker());
		}

		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);

	useEffect(() => {
		if (marker && options) {
			marker.setOptions(options);
		}
	}, [marker, options]);

	return null;
};
