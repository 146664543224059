import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocations } from "features/LocationsPage/locations";
import { Header } from "shared/components/BasicHeader";
import { Footer } from "shared/components/Footer";
import css from "./styles.module.scss";
import birthdayPagePicture from "assets/images/birthday-page-header.jpg";
import gradientBar from "assets/images/gradient-bar.png";
import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import { useNavigate } from "react-router-dom";
import { MobilePackages } from "./MobilePackages";
import { DesktopPackages } from "./DesktopPackages";
import { getEventsConfig } from "features/events/eventsConfig";
import i18n from "i18n";
import cn from "classnames";
import { EventsPagesForm } from "features/events/EventsPagesForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

export const BirthdayPage = () => {
  const { t } = useTranslation();
  const { city } = useParams();
  const locations = getLocations();
  const navigate = useNavigate();

  const topRef = useRef();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  const packages = useMemo(() => {
    const eventsConfig = getEventsConfig(city);
    return eventsConfig?.birthday || [];
  }, [city, i18n.language]);

  const footerScrollCallback = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    topRef.current.focus();
  };

  const addons = useMemo(() => {
    const firstPackage = packages[0] || { features: [] };
    return firstPackage.features.map((feature) => feature.label);
  }, [packages]);

  return (
    <div className={css.BirthdayPage}>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {isSubmitLoading && <LoadingOverlay />}
      <Header city={city} cityName={locations[city]?.title || ""} />
      <div className={css.container}>
        <div className={css.flexContainer}>
          <div className={css.birthdayPagePictureContainer}>
            <img
              src={birthdayPagePicture}
              alt="birthday page picture"
              className={css.birthdayPagePicture}
              ref={topRef}
            />
            <div className={css.birthdayPagePictureTextContainer}>
              <Text
                className={cn(css.birthdayPagePictureText, css.lineOne)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineOne")}
              </Text>
              <Text
                className={cn(css.birthdayPagePictureText, css.lineTwo)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineTwo")}
              </Text>
            </div>
          </div>
          <main>
            {isMobile ? (
              <MobilePackages packages={packages} />
            ) : (
              <DesktopPackages packages={packages} addons={addons} t={t} />
            )}
            <Text type="white" bold className={css.dateText}>
              {t(`birthdayPage.updateDate.${city}`)}
            </Text>
            <img
              className={css.gradientBar}
              src={gradientBar}
              alt="Gradient Bar"
            />
            <Text
              className={css.additionalPaymentsTitle}
              type="white"
              bold
              secondaryFamily
              alignCenter
            >
              {t("birthdayPage.additionalPayment")}
            </Text>
            <Text className={css.additionalPayments} type="white" alignCenter>
              {t(`birthdayPage.additionalPayments.${city}`)}
            </Text>
            <div className={css.buttonSection}>
              <Button
                text={t("events.bookingButton")}
                onClick={() => {
                  navigate(`/${city}/booking`);
                }}
              ></Button>
            </div>
            <img
              className={css.gradientBar}
              src={gradientBar}
              alt="Gradient Bar"
            />
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_EVENTS_RECAPTCHA_KEY}
              container={{
                element: "recaptchaContainer",
                parameters: {
                  badge: "bottomleft",
                  size: "invisible",
                },
              }}
            >
              <EventsPagesForm
                setIsSubmitLoading={setIsSubmitLoading}
                pageType="Birthday"
                city={city}
              ></EventsPagesForm>
            </GoogleReCaptchaProvider>
          </main>
        </div>
      </div>
      <Footer scrollCallback={footerScrollCallback} />
    </div>
  );
};
