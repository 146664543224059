import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Text } from "shared/components/Text";
import { IconLink } from "shared/components/IconLink";
import selectedLinkMark from "assets/icons/selected-link-mark.png";
import mobileBar from "assets/images/mobile-bar.png";
import mobileClose from "assets/icons/mobile-close.png";
import mobileCloseHover from "assets/icons/mobile-close-hover.png";
import facebookIcon from "assets/icons/facebook.png";
import facebookHoverIcon from "assets/icons/facebook-hover.png";
import instagramIcon from "assets/icons/instagram.png";
import instagramHoverIcon from "assets/icons/instagram-hover.png";
import tiktokIcon from "assets/icons/tiktok.png";
import tiktokHoverIcon from "assets/icons/tiktok-hover.png";

import css from "./styles.module.scss";
import { ResetLocationButton } from "shared/components/ResetLocationButton";

export const MobileMenu = ({
  mobileMenuVisibility,
  setMobileMenuVisibility,
  sections,
  selectedSection,
  selectSection,
  cityName,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={cn(css.MobileMenu, {
        [css.delayedHidden]: !mobileMenuVisibility,
        [css.visible]: mobileMenuVisibility,
      })}
    >
      <div className={css.content}>
        <div
          className={cn(css.menu, {
            [css.arrive]: mobileMenuVisibility,
            [css.disappear]: !mobileMenuVisibility,
          })}
        >
          <div className={css.menuHeader}>
            <button
              className={css.mobileClose}
              onClick={() => setMobileMenuVisibility(false)}
            >
              <img
                title="Mobile menu close trigger"
                src={mobileClose}
                className={css.mobileCloseIcon}
                alt="mobile menu close trigger"
                onTouchStart={(e) => {
                  e.target.src = mobileCloseHover;
                }}
                onTouchEnd={(e) => {
                  e.target.src = mobileClose;
                }}
              />
            </button>
          </div>
          <div className={css.menuItemsContainer}>
            <div className={css.linksContainer}>
              {sections.map(({ title, ref, url }) => (
                <button
                  key={title}
                  onClick={() => {
                    if (ref) {
                      selectSection(title, ref);
                    } else if (url) {
                      navigate(url);
                    }
                  }}
                  className={css.link}
                >
                  <Text
                    type="white"
                    className={cn(css.sectionText, {
                      [css.selectedSectionText]: title === selectedSection,
                    })}
                  >
                    {title}
                  </Text>
                  <img
                    title="Mark for mobile selected section"
                    src={selectedLinkMark}
                    alt="mark for mobile selected section"
                    className={cn(css.selectedLinkMark, {
                      [css.hidden]: title !== selectedSection,
                    })}
                  />
                </button>
              ))}
              <button
                className={cn(css.bookLink, css.link)}
                onClick={() => navigate("booking")}
              >
                <Text type="white" className={cn(css.bookText)}>
                  {t("header.book")}
                </Text>
              </button>
            </div>
            <div className={css.bottomContainer}>
              <div className={css.iconsContainer}>
                <IconLink
                  href="https://www.facebook.com/pixelxlpoland"
                  icon={facebookIcon}
                  iconHovered={facebookHoverIcon}
                  newTab
                  className={css.icon}
                />
                <IconLink
                  href="https://www.instagram.com/pixelxlpoland/"
                  icon={instagramIcon}
                  iconHovered={instagramHoverIcon}
                  newTab
                  className={css.icon}
                />
                <IconLink
                  href="https://www.tiktok.com/@pixelxlpoland"
                  icon={tiktokIcon}
                  iconHovered={tiktokHoverIcon}
                  newTab
                  className={css.icon}
                />
              </div>
            </div>
          </div>
        </div>
        <img
          title="Decoration bar"
          src={mobileBar}
          className={cn(css.mobileBar, {
            [css.arrive]: mobileMenuVisibility,
            [css.disappear]: !mobileMenuVisibility,
          })}
          alt="decoration bar"
        />
        <button
          className={cn(css.mobileMenuCloseOverlay)}
          onClick={() => setMobileMenuVisibility(false)}
        />
      </div>
    </div>
  );
};
