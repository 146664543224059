import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

export const useMutationObservable = (
  target,
  callback,
  options = DEFAULT_OPTIONS
) => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const observer = new MutationObserver(callback);
    setObserver(observer);
  }, [callback, setObserver]);

  useEffect(() => {
    if (!observer) return;
    const { config } = options;
    observer.observe(target, config);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, target, options]);
};
