import ky from "ky";

const requestClient = ky.create({
  prefixUrl:
    "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/sign-up-for-pixel-classes",
});

export const signUpForClasses = ({ name, surname, age, email, phone, token, referralCode }) => {
  return requestClient.post("sign-up", {
    body: JSON.stringify({
      name,
      surname,
      age,
      email,
      phone,
      referralCode,
      recaptchaToken: token,
    }),
  });
};
