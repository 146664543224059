import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pl from "translations/pl.json";
import eng from "translations/eng.json";

const mapNavigatorLanguageToI18nLanguage = (language) => {
  const languageStored = window.localStorage.getItem("i18nextLng");

  if (languageStored && languageStored !== "pl" && languageStored !== "eng") {
    window.localStorage.setItem("i18nextLng", "eng");
    return "eng";
  }

  if (languageStored) {
    return languageStored;
  }

  const languageSplit = language.split("-");
  const languageAnalized = languageSplit[0];

  if (languageAnalized === "pl") {
    return "pl";
  } else {
    return "eng";
  }
};

i18n.use(initReactI18next).init({
  fallbackLng: mapNavigatorLanguageToI18nLanguage(navigator.languages[0]),
  debug: process.env.NODE_ENV === "development",
  react: {
    useSuspense: false,
  },
  resources: {
    pl: {
      translation: pl,
    },
    eng: {
      translation: eng,
    },
  },
  returnObjects: true,
});

export default i18n;
