import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";

import css from "./styles.module.scss";

export const VoucherSection = ({ passedRef, city }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={css.VoucherSection} ref={passedRef}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("voucherSection.title")}
      </Text>
      <Button
        onClick={() => navigate(`/${city}/vouchers`)}
        text={t("voucherSection.button")}
      />
    </div>
  );
};
