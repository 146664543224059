import cn from "classnames";

import css from "./styles.module.scss";

export const TextInput = ({
  label,
  value,
  setValue,
  error,
  type,
  autoComplete,
  placeholder,
  mode = "form",
}) => {
  return (
    <div className={css.TextInput}>
      <div
        className={cn({
          [css.inputContainerForm]: mode === "form",
          [css.inputContainerClasses]: mode === "classes",
        })}
      >
        {label && (
          <div className={css.labelContainer}>
            <label htmlFor={label} className={css.label}>
              {label}
            </label>
          </div>
        )}
        <input
          id={label}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className={cn({
            [css.inputForm]: mode === "form",
            [css.inputClasses]: mode === "classes",
            [css.errorInput]: error,
          })}
          type={type || "text"}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
      </div>
      {error && <p className={css.errorText}>{error}</p>}
    </div>
  );
};
