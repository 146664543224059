import ky from "ky";

class NewsletterApi {
  requestClient;

  constructor() {
    this.requestClient = ky.create({
      prefixUrl:
        "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/newsletter-api",
    });
  }

  createContact({ email, language, token }) {
    return this.requestClient
      .post("request", {
        json: {
          type: "createContact",
          recaptchaToken: token,
          data: {
            email,
            language,
          },
        },
      })
      .json();
  }
}

export const newsletterApi = new NewsletterApi();
