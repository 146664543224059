import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";

import { Text } from "shared/components/Text";
import arrowRight from "assets/images/arrow-right.png";
import arrowLeft from "assets/images/arrow-left.png";

import { getOffers } from "./offers";
import css from "./styles.module.scss";
import { OneOfferView } from "./offerViews/OneOfferView";
import { TwoOffersView } from "./offerViews/TwoOffersView";
import { ThreeOffersView } from "./offerViews/ThreeOffersView";
import { FourOffersView } from "./offerViews/FourOffersView";
import { FiveOffersView } from "./offerViews/FiveOffersView";
import { MobileOffersView } from "./offerViews/MobileOffersView";

const MAX_VISIBLE_OFFERS = 5;

export const CareerSection = ({ passedRef }) => {
  const { t, i18n } = useTranslation();

  const content = t("careerSection.content", {
    returnObjects: true,
  });

  const offers = useMemo(() => {
    return getOffers();
  }, [i18n.language]);

  const [offersPage, setOffersPage] = useState(0);
  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  const viewedOffers = useMemo(() => {
    const sliced = offers.slice(
      offersPage * MAX_VISIBLE_OFFERS,
      (offersPage + 1) * MAX_VISIBLE_OFFERS
    );

    const finalOffers = [];

    for (let i = 0; i < sliced.length; ++i) {
      finalOffers.push(sliced[i]);
    }

    return finalOffers;
  }, [offers, offersPage]);

  const offerView = useMemo(() => {
    if (isMobile) {
      return <MobileOffersView offers={offers} />;
    }

    switch (viewedOffers.length) {
      case 1:
        return <OneOfferView offer={viewedOffers[0]} />;
      case 2:
        return <TwoOffersView offers={viewedOffers} />;
      case 3:
        return <ThreeOffersView offers={viewedOffers} />;
      case 4:
        return <FourOffersView offers={viewedOffers} />;
      case 5:
        return <FiveOffersView offers={viewedOffers} />;
      default:
        console.error("wrong length of viewedoffers");
        return <></>;
    }
  }, [viewedOffers, isMobile]);

  if (viewedOffers.length === 0) {
    return <></>;
  }

  return (
    <div ref={passedRef} className={css.CareerSection}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("careerSection.title")}
      </Text>
      {t("careerSection.subTitle") && (
        <Text type="white" className={css.subTitle}>
          {t("careerSection.subTitle")}
        </Text>
      )}
      {content.map((el) => (
        <Text key={el} type="lighterPink" className={css.content}>
          {el}
        </Text>
      ))}
      <div className={css.buttonsContainer}>
        {!isMobile && (offersPage + 1) * MAX_VISIBLE_OFFERS < offers.length && (
          <button
            className={css.nextPageButton}
            onClick={() => {
              console.log(">");
              setOffersPage((prev) => prev + 1);
            }}
          >
            <img src={arrowRight} alt="next page arrow" />
          </button>
        )}
        {!isMobile && offersPage > 0 && (
          <button
            className={css.prevPageButton}
            onClick={() => {
              setOffersPage((prev) => prev - 1);
            }}
          >
            <img src={arrowLeft} alt="previous page arrow" />
          </button>
        )}
      </div>
      {offerView}
    </div>
  );
};
