import { useTranslation } from "react-i18next";
import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const ThankYouStep = ({ name }) => {
  const { t } = useTranslation();

  return (
    <div className={css.ThankYouStep}>
      <Text className={css.prompt} type="pink" secondaryFamily alignCenter>
        {t("form.thankYouPrompt", { name })}
      </Text>
      <Text className={css.prompt} type="lightBlue" secondaryFamily alignCenter>
        {t("form.remoteSeeYou")}
      </Text>
    </div>
  );
};
