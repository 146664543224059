import cn from "classnames";

import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const Button = ({
  text,
  onClick,
  className,
  textClassname,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(css.Button, className)}
      disabled={disabled}
    >
      <Text className={cn(css.text, textClassname)} type="white" bold>
        {text}
      </Text>
    </button>
  );
};
