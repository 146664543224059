import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import sectionSeparator from "assets/images/section-separator.png";
import { ReactComponent as SmallGroupIcon } from "assets/icons/small-group.svg";
import { ReactComponent as BigGroupIcon } from "assets/icons/big-group.svg";
import SinglePersonIcon from "assets/icons/single-person.png";
import titleGlow from "assets/images/title-glow.png";

import css from "./styles.module.scss";

export const PriceListSection = ({
  passedRef,
  bookingUrl,
  city,
  locations,
  priceBanner,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const content = t("priceListSection.content", {
    returnObjects: true,
  });

  const contentAfterPriceList = t(
    `priceListSection.contentAfterPriceList.${city}`,
    {
      returnObjects: true,
    }
  );

  return (
    <div ref={passedRef} className={css.PriceListSection}>
      <Text type="white" className={css.title} bold secondaryFamily>
        {t("priceListSection.title")}
      </Text>
      <img src={priceBanner} className={css.priceBanner}></img>
      <Button
        text={t("shared.checkPricesText")}
        className={css.buyTicketButton}
        onClick={() => {
          navigate(bookingUrl);
        }}
      />
      {contentAfterPriceList.map((el) => (
        <Text key={el} type="white" className={css.content}>
          {el}
        </Text>
      ))}
    </div>
  );
};
