import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "shared/components/Text";
import logo from "assets/images/header-logo.png";
import arrow from "assets/images/arrow-down.png";
import refreshIcon from "assets/icons/refresh-icon.png";

import css from "./styles.module.scss";
import { EmailStep } from "./EmailStep";
import { AlreadyFilledStep } from "./AlreadyFilledStep";
import { NewUserStep } from "./NewUserStep";
import { FinalConsentStep } from "./FinalConsentStep";
import { ThankYouStep } from "./ThankYouStep";
import {
  GoogleReCaptchaContext,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { LanguageChooser } from "shared/components/LanguageChooser";

export const UserDataFormPage = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [nick, setNick] = useState("");
  const [childrenConsent, setChildrenConsent] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [zipCode, setZipCode] = useState("");

  const [alreadyExistingUserId, setAlreadyExistingUserId] = useState();
  const [numberOfSubmits, setNumberOfSubmits] = useState();

  const [step, setStep] = useState("email");

  useEffect(() => {
    document.title = "Pixel XL Data Form";
  }, []);

  const stepToRender = useMemo(() => {
    switch (step) {
      case "email":
        return (
          <EmailStep
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            setAlreadyExistingUserId={setAlreadyExistingUserId}
            setNumberOfSubmits={setNumberOfSubmits}
          />
        );
      case "alreadyFilled":
        return (
          <AlreadyFilledStep
            email={email}
            setChildrenConsent={setChildrenConsent}
            alreadyExistingUserId={alreadyExistingUserId}
            numberOfSubmits={numberOfSubmits}
            setStep={setStep}
          />
        );
      case "newUser":
        return (
          <NewUserStep
            setStep={setStep}
            name={name}
            setName={setName}
            surname={surname}
            setSurname={setSurname}
            nick={nick}
            setNick={setNick}
            setDateOfBirth={setDateOfBirth}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        );
      case "consent":
        return (
          <FinalConsentStep
            email={email}
            name={name}
            surname={surname}
            nick={nick}
            dateOfBirth={dateOfBirth}
            zipCode={zipCode}
            setStep={setStep}
          />
        );
      case "thankYou":
        return <ThankYouStep name={name} />;
    }
  }, [
    step,
    setStep,
    email,
    setEmail,
    name,
    setName,
    surname,
    setSurname,
    nick,
    zipCode,
    setNick,
    setZipCode,
    setDateOfBirth,
    setChildrenConsent,
  ]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <div className={css.UserDataFormPage}>
        <div className={css.upperContainer}>
          {step !== "email" && step !== "thankYou" && (
            <button
              className={css.upperButton}
              onClick={() => {
                if (step === "alreadyFilled" || step === "newUser") {
                  setStep("email");
                } else if (step === "consent") {
                  setStep("newUser");
                }
              }}
            >
              <img
                src={arrow}
                alt="back-arrow"
                title="Back Arrow"
                className={css.backArrow}
              />
            </button>
          )}
          {step === "thankYou" && (
            <button
              className={css.upperButton}
              onClick={() => {
                setStep("email");
              }}
            >
              <img
                src={refreshIcon}
                alt="refresh-icon"
                title="Refresh Icon"
                className={css.refreshIcon}
              />
            </button>
          )}
          <img
            src={logo}
            alt="logo"
            title="Pixel Xl logo"
            className={css.logo}
          />
          <div className={css.languageChooserContainer}>
            <LanguageChooser
              className={css.languageChooser}
              borderColor="blue"
            />
          </div>
        </div>
        <div className={css.stepContainer}>{stepToRender}</div>
      </div>
    </GoogleReCaptchaProvider>
  );
};
