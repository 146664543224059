import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setupPlan4u } from "shared/utils";
import banner from "assets/images/voucher-banner-v2.png";
import { getLocations } from "features/LocationsPage/locations";

import { Header } from "./Header";
import css from "./styles.module.scss";
import { useMemo } from "react";

export const VoucherPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { city } = useParams();

  useEffect(() => {
    setupPlan4u();
    document.title = "PixelXL Board Booking";
  }, []);

  const locations = useMemo(() => {
    return getLocations();
  }, [i18n.language]);

  const voucherPageUrl = useMemo(() => {
    return locations[city].voucherPageUrl;
  }, [locations, city]);

  return (
    <div className={css.VoucherPage}>
      <Header city={city} cityName={locations[city].title} />
      {/* <img src={banner} className={css.banner} /> */}
      <iframe
        id="fBookIt"
        src={voucherPageUrl}
        width="100%"
        frameBorder="0"
        scrolling="no"
        className={css.iframe}
      ></iframe>
    </div>
  );
};
