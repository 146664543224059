import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import sectionSeparator from "assets/images/section-separator.png";
import phoneIcon from "assets/icons/phone.png";
import mailIcon from "assets/icons/mail.png";
import addressIcon from "assets/icons/address.png";

import css from "./styles.module.scss";

export const ContactSection = ({ passedRef, address, email }) => {
  const { t } = useTranslation();

  return (
    <div ref={passedRef} className={css.ContactSection}>
      <div className={css.contentBox}>
        <Text type="black" className={css.title} bold secondaryFamily>
          {t("contactSection.title")}
        </Text>
        {t("contactSection.subTitle") && (
          <Text type="darkBlue" className={css.subTitle}>
            {t("contactSection.subTitle")}
          </Text>
        )}
        <div className={css.contactContainer}>
          <div className={css.contactEntry}>
            <img
              src={phoneIcon}
              alt="phone icon"
              title="Phone icon"
              className={css.phoneIcon}
            />
            <a href="tel:22-307-26-72">
              <Text type="black" className={css.contact}>
                +48 22-307-26-72
              </Text>
            </a>
          </div>
          <div className={css.contactEntry}>
            <img
              src={mailIcon}
              alt="mail icon"
              title="Mail icon"
              className={css.mailIcon}
            />
            <a href={`mailto:${email}`}>
              <Text type="black" className={css.contact}>
                {email}
              </Text>
            </a>
          </div>
          <div className={css.contactEntry}>
            <img
              src={addressIcon}
              alt="address icon"
              title="Address icon"
              className={css.addressIcon}
            />
            <Text type="black" className={css.contact}>
              {address}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
