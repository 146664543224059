import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import { TextInput } from "shared/components/TextInput";
import { Select } from "shared/components/Select";

import css from "./styles.module.scss";
import { Spinner } from "../Spinner";

const zipCodePattern = /^\d{2}-?\d{3}$/;

export const NewUserStep = ({
  setStep,
  name,
  setName,
  surname,
  setSurname,
  nick,
  setNick,
  setDateOfBirth,
  zipCode,
  setZipCode,
}) => {
  const { t } = useTranslation();

  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [nickError, setNickError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [dayOfBirth, setDayOfBirth] = useState();
  const [monthOfBirth, setMonthOfBirth] = useState();
  const [yearOfBirth, setYearOfBirth] = useState();

  const [isDayOfBirthWrong, setIsDayOfBirthWrong] = useState(false);
  const [isMonthOfBirthWrong, setIsMonthOfBirthWrong] = useState(false);
  const [isYearOfBirthWrong, setIsYearOfBirthWrong] = useState(false);

  const DAY_OF_BIRTH_OPTIONS = useMemo(() => {
    return Array.from({ length: 31 }, (_, i) => {
      return {
        label: i + 1,
        value: i + 1,
      };
    });
  }, [t]);

  const MONTH_OF_BIRTH_OPTIONS = useMemo(() => {
    return [
      { label: t("form.months.january"), value: 1 },
      { label: t("form.months.february"), value: 2 },
      { label: t("form.months.march"), value: 3 },
      { label: t("form.months.april"), value: 4 },
      { label: t("form.months.may"), value: 5 },
      { label: t("form.months.june"), value: 6 },
      { label: t("form.months.july"), value: 7 },
      { label: t("form.months.august"), value: 8 },
      { label: t("form.months.september"), value: 9 },
      { label: t("form.months.october"), value: 10 },
      { label: t("form.months.november"), value: 11 },
      { label: t("form.months.december"), value: 12 },
    ];
  }, [t]);

  const YEAR_OF_BIRTH_OPTIONS = useMemo(() => {
    // options between current year and current year - 100
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 100 }, (_, i) => {
      return {
        value: currentYear - i,
        label: currentYear - i,
      };
    });
  }, [t]);

  const onSubmit = async ({
    name,
    surname,
    nick,
    dayOfBirth,
    monthOfBirth,
    yearOfBirth,
  }) => {
    let wrongSubmit = false;
    setIsDayOfBirthWrong(false);
    setIsMonthOfBirthWrong(false);
    setIsYearOfBirthWrong(false);
    setNameError("");
    setSurnameError("");
    setNickError("");
    setDateOfBirthError("");

    if (!name) {
      setNameError(t("form.errors.emptyName"));
      wrongSubmit = true;
    } else if (name.length > 64) {
      setNameError(t("form.errors.nameTooLong"));
      wrongSubmit = true;
    }
    if (!surname) {
      setSurnameError(t("form.errors.emptySurname"));
      wrongSubmit = true;
    } else if (surname.length > 64) {
      setSurnameError(t("form.errors.surnameTooLong"));
      wrongSubmit = true;
    }
    if (nick.length > 64) {
      setNickError(t("form.errors.nickTooLong"));
      wrongSubmit = true;
    }
    if (zipCode.trim() && !zipCodePattern.test(zipCode.trim())) {
      setZipCodeError(t("form.errors.wrongZipCode"));
      wrongSubmit = true;
    }

    if (!dayOfBirth) {
      setIsDayOfBirthWrong(true);
    }
    if (!monthOfBirth) {
      setIsMonthOfBirthWrong(true);
    }
    if (!yearOfBirth) {
      setIsYearOfBirthWrong(true);
    }

    const dateOfBirth = new Date(yearOfBirth, monthOfBirth - 1, dayOfBirth);

    // happens if user picks something like 31st of february or any of the above date checks fail

    if (
      dateOfBirth.getMonth() !== monthOfBirth - 1 ||
      dateOfBirth.getDate() !== dayOfBirth
    ) {
      wrongSubmit = true;
      setDateOfBirthError(t("form.errors.wrongDateOfBirth"));
    }

    if (new Date().getFullYear() - yearOfBirth < 16) {
      wrongSubmit = true;
      setIsYearOfBirthWrong(true);
      setDateOfBirthError(t("form.errors.notAdult"));
    }

    if (wrongSubmit) {
      return;
    }

    setDateOfBirth(dateOfBirth.toLocaleDateString("pl-PL"));
    setStep("consent");
  };

  return (
    <form
      className={css.NewUserStep}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Text className={css.prompt} type="pink" secondaryFamily>
        {t("form.newUserPrompt")}
      </Text>
      <Text className={css.prompt} type="lightBlue" secondaryFamily>
        {t("form.remoteFillPrompt")}
      </Text>
      <TextInput
        value={name}
        setValue={setName}
        label={t("form.fields.name")}
        error={nameError}
        autoComplete="given-name"
        mode="form"
      />
      <TextInput
        value={surname}
        setValue={setSurname}
        label={t("form.fields.surname")}
        error={surnameError}
        autoComplete="family-name"
        mode="form"
      />
      <TextInput
        value={nick}
        setValue={setNick}
        label={t("form.fields.nick")}
        error={nickError}
        autoComplete="nickname"
        mode="form"
      />
      <TextInput
        value={zipCode}
        setValue={setZipCode}
        label={t("form.fields.zipCode")}
        error={zipCodeError}
        autoComplete="postal-code"
        mode="form"
      />
      <div className={css.dropdownsContainer}>
        <div className={css.labelContainer}>
          <Text className={css.dateOfBirthLabel} type="black" secondaryFamily>
            {t("form.dateOfBirthLabel")}
          </Text>
        </div>
        <div className={css.innerDropdownsContainer}>
          <Select
            options={DAY_OF_BIRTH_OPTIONS}
            autoComplete="bday-day"
            value={dayOfBirth}
            setValue={setDayOfBirth}
            placeholder={t("form.fields.dayOfBirth")}
            isWrong={isDayOfBirthWrong}
          />
          <Select
            options={MONTH_OF_BIRTH_OPTIONS}
            autoComplete="bday-month"
            value={monthOfBirth}
            setValue={setMonthOfBirth}
            placeholder={t("form.fields.monthOfBirth")}
            isWrong={isMonthOfBirthWrong}
          />
          <Select
            options={YEAR_OF_BIRTH_OPTIONS}
            autoComplete="bday-year"
            value={yearOfBirth}
            setValue={setYearOfBirth}
            placeholder={t("form.fields.yearOfBirth")}
            isWrong={isYearOfBirthWrong}
            focusedValue={new Date().getFullYear() - 16}
          />
        </div>
      </div>
      {dateOfBirthError && <p className={css.errorText}>{dateOfBirthError}</p>}
      <Button
        text={t("form.nextButtonText")}
        className={css.button}
        textClassname={css.buttonText}
        onClick={() =>
          onSubmit({
            name,
            surname,
            nick,
            dayOfBirth,
            monthOfBirth,
            yearOfBirth,
          })
        }
      />
    </form>
  );
};
