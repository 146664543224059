import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const Toast = ({ text, failed }) => {
  return (
    <div className={cn(css.Toast, { [css.failed]: failed })}>
      <Text type="green" className={css.text}>
        {text}
      </Text>
    </div>
  );
};
