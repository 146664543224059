import css from "./styles.module.scss";

export const Pixel = ({ top, left, bottom, right, width, height }) => {
  return (
    <div
      className={css.Pixel}
      style={{
        top,
        left,
        bottom,
        right,
        width,
        height,
      }}
    />
  );
};
