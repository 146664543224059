import React from "react";
import checkMark from "assets/images/checkmark.png";
import { useTranslation } from "react-i18next";
import { Text } from "shared/components/Text";
import css from "./styles.module.scss";

export const Feature = ({ feature }) => {
  const { t } = useTranslation();

  const renderFeature = (feature) => {
    const featureType = typeof feature.value;

    if (featureType === "boolean") {
      return feature.value ? (
        <img src={checkMark} alt="Checkmark" className={css.img} />
      ) : null;
    }
    return (
      <Text type="white" className={css.text} bold secondaryFamily alignCenter>
        {feature.value}
      </Text>
    );
  };

  return <>{renderFeature(feature)}</>;
};
