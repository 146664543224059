import cn from "classnames";

import { MobileJobOffer } from "./MobileJobOffer";
import css from "./styles.module.scss";

export const MobileOffersView = ({ offers }) => {
  const xms = window.matchMedia("(max-width: 400px)").matches;

  const mul = xms ? 0.74 : 1;

  return (
    <div
      className={cn(css.MobileOffersView, {
        [css.biggerBottomPadding]: offers.length % 2 === 0,
      })}
    >
      <div
        className={cn(css.row, {
          [css.singleOfferRow]: !offers[1],
        })}
        style={{
          marginBottom: `calc(40px - ${(offers.length / 2) * 150 * mul}px)`,
        }}
      >
        {offers.map((offer, i) => {
          return (
            <MobileJobOffer
              key={`firstRowOffer${i}`}
              offer={offer}
              style={{
                transform: `translate3d(${120 * mul * (i % 2)}px, calc(${-50 * (i % 2)}% + ${100 * mul * (i % 2) - 80 * i * mul}px), 0)`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
