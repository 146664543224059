import cn from "classnames";

import css from "./styles.module.scss";
import { Text } from "shared/components/Text";

export const NewsletterInput = ({ email, setEmail, error, className }) => {
  return (
    <div className={cn(css.NewsletterInput, className)}>
      <div className={css.inputContainer}>
        <input
          value={email}
          placeholder={"Email *"}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          autoComplete={"email"}
          className={cn(css.input, { [css.errorInput]: error })}
        />
        <Text className={css.error} secondaryFamily={true} type="errorRed">
          {error}
        </Text>
      </div>
    </div>
  );
};
