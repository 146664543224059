import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setupPlan4u } from "shared/utils";
import { getLocations } from "features/LocationsPage/locations";
import { LanguageChooser } from "shared/components/LanguageChooser";

import { Header } from "shared/components/BasicHeader";
import css from "./styles.module.scss";
import { Spinner } from "shared/components/Spinner";
import { Text } from "shared/components/Text";

function receiveMessage(e) {
  try {
    if (typeof e.data === "number") {
      window.scrollTo(0, e.data, { behavior: "smooth" });
    }
  } catch (err) {}
}

export const BookingPage = () => {
  const { t, i18n } = useTranslation();
  const { city } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(true);

  const locations = useMemo(() => {
    return getLocations();
  }, [i18n.language]);

  const bookingUrl = useMemo(() => {
    return locations[city].bookingUrl[i18n.language];
  }, [i18n.language, locations, city]);

  useEffect(() => {
    document.title = `PixelXL Booking - ${city}`;

    const checkTime = () => {
      const now = new Date();
      const startBlockTime = new Date(now.getFullYear(), 11, 31, 19, 0, 0);
      const endBlockTime = new Date(2025, 0, 1, 0, 0, 0);

      if (now >= startBlockTime && now < endBlockTime) {
        setShowIframe(false);
      } else {
        setShowIframe(true);
      }
    };

    checkTime();
  }, []);

  const navigate = useNavigate();

  return (
    <div className={css.BookingPage}>
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <Header city={city} cityName={locations[city].title} />
      {showIframe ? (
        <>
          <Header city={city} cityName={locations[city].title} />
          <img
            src={locations[city].bookingPageBanner}
            className={css.banners}
          />
          {isLoading && <Spinner type="small" />}
          <iframe
            id="fBookIt"
            src={bookingUrl}
            width="100%"
            frameBorder="0"
            scrolling="no"
            className={css.iframe}
            onLoad={() => {
              setupPlan4u();
              setIsLoading(false);
            }}
          ></iframe>
        </>
      ) : (
        <div className={css.temporaryTextContainer}>
          <Text
            type="white"
            bold
            alignCenter
            secondaryFamily
            className={css.techincalBreakText}
          >
            {t("bookingPage.techincalBreakdown")}
          </Text>
        </div>
      )}
    </div>
  );
};
