import ky from "ky";

export const checkIfUsersEmailExists = ({ email, city }) => {
  const requestClient = ky.create({
    prefixUrl:
      "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/check_if_user_data_exists",
  });

  const searchParams = new URLSearchParams();
  searchParams.set("email", email);
  searchParams.set("city", city);

  return requestClient
    .get("check", {
      searchParams,
    })
    .json();
};

export const collectUserData = ({
  email,
  name,
  surname,
  nick,
  dateOfBirth,
  zipCode,
  withChildren,
  marketingConsent,
  token,
  city,
}) => {
  const requestClient = ky.create({
    prefixUrl:
      "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/collect-user-data",
  });

  return requestClient
    .post("collect", {
      body: JSON.stringify({
        email,
        name,
        surname,
        nick,
        dateOfBirth,
        zipCode,
        withChildren,
        marketingConsent,
        recaptchaToken: token,
        city,
      }),
    })
    .json();
};

export const updateUserData = ({ userId, withChildren, numberOfSubmits }) => {
  const requestClient = ky.create({
    prefixUrl:
      "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/update-user-data",
  });

  return requestClient
    .post("update", {
      body: JSON.stringify({
        userId,
        withChildren,
        numberOfSubmits,
      }),
    })
    .json();
};
