import { JobOffer } from "../../JobOffer";
import { Pixel } from "../../Pixel";
import css from "./styles.module.scss";

export const ThreeOffersView = ({ offers }) => {
  const pixelMultiplier = window.matchMedia("(min-width: 2800px)").matches
    ? window.innerWidth / 1920
    : 1;

  return (
    <div className={css.ThreeOffersView}>
      <JobOffer offer={offers[0]} />
      <JobOffer offer={offers[1]} />
      <JobOffer offer={offers[2]} className={css.absoluteJobOffer} />
      <Pixel
        width={70 * pixelMultiplier}
        height={70 * pixelMultiplier}
        left={`calc(50% - ${270 * pixelMultiplier}px)`}
        bottom={`${80 * pixelMultiplier}px`}
      />
      <Pixel
        width={40 * pixelMultiplier}
        height={40 * pixelMultiplier}
        left={`calc(50% + ${145 * pixelMultiplier}px)`}
        bottom={`${48 * pixelMultiplier}px`}
      />
      <Pixel
        width={30 * pixelMultiplier}
        height={30 * pixelMultiplier}
        left={`calc(50% - ${15 * pixelMultiplier}px)`}
        top={`${80 * pixelMultiplier}px`}
      />
      {pixelMultiplier === 1 && (
        <Pixel
          width={30 * pixelMultiplier}
          height={30 * pixelMultiplier}
          left={`calc(50% + ${310 * pixelMultiplier}px)`}
          bottom={`${315 * pixelMultiplier}px`}
        />
      )}
    </div>
  );
};
