import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";

import { Text } from "shared/components/Text";
import { IconLink } from "shared/components/IconLink";
import facebookIcon from "assets/icons/facebook.png";
import facebookHoverIcon from "assets/icons/facebook-hover.png";
import instagramIcon from "assets/icons/instagram.png";
import instagramHoverIcon from "assets/icons/instagram-hover.png";
import tiktokIcon from "assets/icons/tiktok.png";
import tiktokHoverIcon from "assets/icons/tiktok-hover.png";
import boardHorizontal from "assets/images/board-horizontal.png";
import boardVertical from "assets/images/board-vertical.jpg";
import logo from "assets/images/header-logo.png";

import css from "./styles.module.scss";
import { ResetLocationButton } from "shared/components/ResetLocationButton";
import { LanguageChooser } from "shared/components/LanguageChooser";
import { getLocations } from "features/LocationsPage/locations";

const ANIMATION_TIME = 15000;
const PIXEL_AMOUNT = 50;
const MOBILE_PIXEL_AMOUNT = 20;

export const LocationInProgressWithoutCountdownPage = ({ city }) => {
  const { t } = useTranslation();

  const locations = useMemo(() => {
    return getLocations();
  }, []);

  const [animationTime, setAnimationTime] = useState(0);
  const navigate = useNavigate();

  const isMobile = window.matchMedia("(max-width: 820px)").matches;

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationTime((prev) => prev + 30);
    }, 30);

    return () => clearInterval(interval);
  }, []);

  const getPixelAnimationStyle = (index) => {
    let maxHeight = window.innerHeight;
    let maxWidth = window.innerWidth;

    let top = 0;
    let left = 0;

    const pixelAmount = isMobile ? MOBILE_PIXEL_AMOUNT : PIXEL_AMOUNT;

    const offset = (index / pixelAmount - 1) * ANIMATION_TIME;
    const offsetedAnimationTime = (animationTime + offset) % ANIMATION_TIME;
    const quarterTime = ANIMATION_TIME / 4;

    if (offsetedAnimationTime < quarterTime) {
      maxHeight = window.innerHeight - 90;

      top = 30 + (offsetedAnimationTime / quarterTime) * maxHeight;
      left = 30;
    } else if (offsetedAnimationTime < quarterTime * 2) {
      maxWidth = window.innerWidth - 90;

      top = window.innerHeight - 60;
      left =
        30 + ((offsetedAnimationTime - quarterTime) / quarterTime) * maxWidth;
    } else if (offsetedAnimationTime < quarterTime * 3) {
      maxHeight = window.innerHeight - 60;

      left = window.innerWidth - 60;
      top =
        maxHeight -
        ((offsetedAnimationTime - quarterTime * 2) / quarterTime) *
          (maxHeight - 30);
    } else {
      maxWidth = window.innerWidth - 60;

      top = 30;
      left =
        maxWidth -
        ((offsetedAnimationTime - quarterTime * 3) / quarterTime) *
          (maxWidth - 30);
    }

    return {
      top,
      left,
    };
  };

  return (
    <div className={css.LocationInProgressPage}>
      <button
        onClick={() => {
          window.localStorage.removeItem("cityUrl");
          navigate("/");
        }}
      >
        <img src={logo} alt="pixel xl logo" className={css.headerLogo} />
      </button>
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp={isMobile}
          borderColor="blue"
        />
      </div>
      <div className={css.locationsButtonContainer}>
        <ResetLocationButton cityName={locations[city].title} />
      </div>
      <>
        {Array.from({
          length: isMobile ? MOBILE_PIXEL_AMOUNT : PIXEL_AMOUNT,
        }).map((_, index) => (
          <div
            key={index}
            className={css.animatedPixel}
            style={{
              top: getPixelAnimationStyle(index).top,
              left: getPixelAnimationStyle(index).left,
            }}
          />
        ))}
      </>

      <div className={css.mainContent}>
        <img
          src={isMobile ? boardVertical : boardHorizontal}
          alt="board"
          className={css.boardImage}
        />
        <Text className={css.prompt} type="white">
          {city === "gdansk"
            ? t(
                "locationInProgressWithoutCountdownPage.gettingReadyWithReservations"
              )
            : t("locationInProgressWithoutCountdownPage.gettingReady")}
        </Text>
        <Text className={css.prompt} type="white">
          {t("locationInProgressWithoutCountdownPage.socials")}
        </Text>
        <div className={css.iconsContainer}>
          <IconLink
            href="https://www.facebook.com/pixelxlpoland"
            icon={facebookIcon}
            iconHovered={facebookHoverIcon}
            newTab
            className={css.icon}
          />
          <IconLink
            href="https://www.instagram.com/pixelxlpoland/"
            icon={instagramIcon}
            iconHovered={instagramHoverIcon}
            newTab
            className={css.icon}
          />
          <IconLink
            href="https://www.tiktok.com/@pixelxlpoland"
            icon={tiktokIcon}
            iconHovered={tiktokHoverIcon}
            newTab
            className={css.icon}
          />
        </div>
      </div>
    </div>
  );
};
