import React, { useState } from "react";
import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import css from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Feature } from "features/events/BirthdayPage/Feature";

export const MobilePackages = ({ packages }) => {
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(1);
  const { t } = useTranslation();
  const activePackage = packages[selectedPackageIndex];

  return (
    <div className={css.mobilePackages}>
      <div className={css.buttonGroup}>
        {packages.map((packageItem, index) => (
          <Button
            key={index}
            text={packageItem.name}
            className={cn(css.packageButton, {
              [css.activeButton]: index === selectedPackageIndex,
            })}
            onClick={() => setSelectedPackageIndex(index)}
            textClassname={css.packageButtonText}
          />
        ))}
      </div>
      <div className={css.packageDetails}>
        <table className={css.table}>
          <tbody>
            {activePackage.features.map((feature, index) => (
              <tr key={index} className={css.tableRow}>
                <td className={css.cellLabel}>
                  <Text type="white" className={css.checkmarkText}>
                    {feature.label}
                  </Text>
                </td>
                <td className={css.cellValue}>
                  <Feature feature={feature} className={css.img} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className={css.footerRow}>
              <td>
                <Text type="white" className={css.columnFooter}>
                  {t("birthdayPage.dayFromMonToThr")}
                </Text>
              </td>
              <td>
                <Text
                  type="white"
                  className={css.columnFooterCell}
                  bold
                  secondaryFamily
                >
                  {activePackage.priceMonToThr}
                </Text>
              </td>
            </tr>
            <tr className={css.footerRow}>
              <td>
                <Text type="white" className={css.columnFooter}>
                  {t("birthdayPage.dayFromFriToSun")}
                </Text>
              </td>
              <td>
                <Text
                  type="white"
                  className={css.columnFooterCell}
                  bold
                  secondaryFamily
                >
                  {activePackage.priceFriToSun}
                </Text>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
