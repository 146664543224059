export const defaultMapOptions = {
  options: {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 1,
    disableDefaultUI: true,
  },
  markerOptions: {
    position: {
      lat: 0,
      lng: 0,
    },
    title: "Pixel XL",
  },
};
