import { JobOffer } from "../../JobOffer";
import { Pixel } from "../../Pixel";
import css from "./styles.module.scss";

export const FiveOffersView = ({ offers }) => {
  const pixelMultiplier = window.matchMedia("(min-width: 2800px)").matches
    ? window.innerWidth / 1920
    : 1;

  return (
    <div className={css.FiveOffersView}>
      <JobOffer offer={offers[0]} />
      <JobOffer offer={offers[1]} />
      <JobOffer offer={offers[2]} />
      <JobOffer offer={offers[3]} className={css.firstAbsoluteJobOffer} />
      <JobOffer offer={offers[4]} className={css.secondAbsoluteJobOffer} />
      <Pixel
        width={70 * pixelMultiplier}
        height={70 * pixelMultiplier}
        left={`calc(50% - ${420 * pixelMultiplier}px)`}
        bottom={`${80 * pixelMultiplier}px`}
      />
      <Pixel
        width={40 * pixelMultiplier}
        height={40 * pixelMultiplier}
        left={`calc(50% - ${20 * pixelMultiplier}px)`}
        bottom={`${40 * pixelMultiplier}px`}
      />
      <Pixel
        width={30 * pixelMultiplier}
        height={30 * pixelMultiplier}
        left={`calc(50% - ${169 * pixelMultiplier}px)`}
        top={`${80 * pixelMultiplier}px`}
      />
      <Pixel
        width={30 * pixelMultiplier}
        height={30 * pixelMultiplier}
        left={`calc(50% + ${340 * pixelMultiplier}px)`}
        bottom={`${105 * pixelMultiplier}px`}
      />
    </div>
  );
};
