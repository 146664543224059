import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "shared/components/Text";
import { TextInput } from "shared/components/TextInput";
import { Button } from "shared/components/Button";
import { signUpForEvents } from "../eventsApi";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import css from "./styles.module.scss";

const emailPattern =
  /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const EventsPagesForm = ({ setIsSubmitLoading, pageType, city }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submitEventsPagesForm");
    return token;
  }, [executeRecaptcha]);

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [connectionError, setConnectionError] = useState("");

  const [wasSuccessful, setWasSuccessful] = useState(false);

  const onSubmit = async () => {
    setConnectionError("");
    setFirstNameError("");
    setSurnameError("");
    setPhoneError("");
    setEmailError("");
    let wrongSubmit = false;

    const emailTrimmed = email.trim();
    if (!emailTrimmed) {
      setEmailError(t("form.errors.emptyEmail"));
      wrongSubmit = true;
    } else if (!emailPattern.test(emailTrimmed)) {
      setEmailError(t("form.errors.incorrectEmail"));
      wrongSubmit = true;
    } else if (emailTrimmed.length > 64) {
      setEmailError(t("form.errors.emailTooLong"));
      wrongSubmit = true;
    }

    if (!firstName) {
      setFirstNameError(t("form.errors.emptyName"));
      wrongSubmit = true;
    } else if (firstName.length > 64) {
      setFirstNameError(t("form.errors.nameTooLong"));
      wrongSubmit = true;
    }

    if (!surname) {
      setSurnameError(t("form.errors.emptySurname"));
      wrongSubmit = true;
    } else if (surname.length > 64) {
      setSurnameError(t("form.errors.surnameTooLong"));
      wrongSubmit = true;
    }

    if (!phone) {
      setPhoneError(t("form.errors.emptyPhone"));
      wrongSubmit = true;
    } else if (phone.length < 7) {
      setPhoneError(t("form.errors.incorrectPhone"));
      wrongSubmit = true;
    }

    if (wrongSubmit) {
      return;
    }

    setIsSubmitLoading(true);
    try {
      const token = await handleReCaptchaVerify();

      await signUpForEvents({
        name: firstName,
        surname,
        phone,
        email,
        token,
        pageType,
        city,
      });

      setWasSuccessful(true);
      setFirstName("");
      setSurname("");
      setPhone("");
      setEmail("");
    } catch (e) {
      console.log(e);
      setConnectionError(t("form.errors.connectionError"));
    }
    setIsSubmitLoading(false);
  };

  return (
    <div className={css.contactSection}>
      {!wasSuccessful ? (
        <>
          <Text
            type="white"
            bold
            className={css.contactSectionTitle}
            alignCenter
            secondaryFamily
          >
            {t("eventsPagesForm.title")}
          </Text>
          <Text
            type="white"
            className={css.contactSectionSubtitle}
            alignCenter
            secondaryFamily
          >
            {t("eventsPagesForm.subtitle")}
          </Text>
          <form className={css.form}>
            <div className={css.formInputsContainer}>
              <div className={css.formColumOneContainer}>
                <div className={css.formTextInputContainer}>
                  <TextInput
                    placeholder={t("eventsPagesForm.firstName")}
                    value={firstName}
                    setValue={setFirstName}
                    mode="classes"
                    error={firstNameError}
                  />
                </div>
                <div className={css.formTextInputContainer}>
                  <TextInput
                    placeholder={t("eventsPagesForm.surname")}
                    value={surname}
                    setValue={setSurname}
                    mode="classes"
                    error={surnameError}
                  />
                </div>
              </div>
              <div className={css.formColumTwoContainer}>
                <div className={css.formTextInputContainer}>
                  <TextInput
                    placeholder={t("eventsPagesForm.phone")}
                    value={phone}
                    setValue={setPhone}
                    type="tel"
                    mode="classes"
                    error={phoneError}
                  />
                </div>
                <div className={css.formTextInputContainer}>
                  <TextInput
                    placeholder={t("eventsPagesForm.email")}
                    value={email}
                    setValue={setEmail}
                    type="email"
                    mode="classes"
                    error={emailError}
                  />
                </div>
              </div>
            </div>
          </form>
          {connectionError && (
            <Text type="red" className={css.connectionErrorText} alignCenter>
              {connectionError}
            </Text>
          )}
          <Button
            text={t("eventsPagesForm.submitButton")}
            className={css.submitButton}
            textClassname={css.submitButtonText}
            onClick={onSubmit}
          />
        </>
      ) : (
        <div className={css.thankYouContainer}>
          <Text
            type="pink"
            className={css.thankYouTextBigger}
            alignCenter
            bold
            secondaryFamily
          >
            {t("eventsPagesForm.thankYou")}
          </Text>
          <Button
            text={t("eventsPagesForm.contactAgainButton")}
            className={css.submitButton}
            textClassname={css.submitButtonText}
            onClick={() => setWasSuccessful(false)}
          />
        </div>
      )}
    </div>
  );
};
