import cn from "classnames";
import parse from "html-react-parser";

import css from "./styles.module.scss";

const styleMap = {
  white: css.white,
  whiteBordered: css.whiteBordered,
  pink: css.pink,
  lighterPink: css.lighterPink,
  darkBlue: css.darkBlue,
  blue: css.blue,
  red: css.red,
  errorRed: css.errorRed,
  purple: css.purple,
  lightBlue: css.lightBlue,
  lightGreen: css.lightGreen,
  orange: css.orange,
  green: css.green,
  black: css.black,
  gold: css.gold,
};

export const Text = ({
  children,
  type,
  className,
  bold,
  secondaryFamily,
  alignCenter,
}) => {
  return (
    <div
      className={cn(css.text, styleMap[type], className, {
        [css.bold]: bold,
        [css.secondary]: secondaryFamily,
        [css.alignCenter]: alignCenter,
      })}
    >
      {/* parse is used to parse tags injected in translation files */}
      {typeof children === "string" ? parse(children) : children}
    </div>
  );
};
