import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "shared/components/BasicHeader";
import { getLocations } from "features/LocationsPage/locations";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Footer } from "shared/components/Footer";
import css from "./styles.module.scss";
import integrationPagePicture from "assets/images/banner.png";
import { Text } from "shared/components/Text";
import gradientBar from "assets/images/gradient-bar.png";
import { Button } from "shared/components/Button";
import i18n from "i18n";
import cn from "classnames";
import { getEventsConfig } from "features/events/eventsConfig";
import { EventsPagesForm } from "features/events/EventsPagesForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LoadingOverlay } from "shared/components/LoadingOverlay";

export const IntegrationPage = () => {
  const { t } = useTranslation();
  const { city } = useParams();
  const navigate = useNavigate();

  const isMobile = window.matchMedia("(max-width: 990px)").matches;

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const locations = getLocations();

  const topRef = useRef();

  const packages = useMemo(() => {
    const cityConfig = getEventsConfig(city);
    return cityConfig ? cityConfig.integration : [];
  }, [city, i18n.language]);

  const footerScrollCallback = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    topRef.current.focus();
  };

  return (
    <div className={css.integrationPage}>
      <div className={css.recaptchaContainer} id="recaptchaContainer" />
      {isSubmitLoading && <LoadingOverlay />}
      <Header city={city} cityName={locations[city]?.title || ""} />
      <div className={css.container}>
        <div className={css.flexContainer}>
          <div className={css.header}>
            <img
              src={integrationPagePicture}
              alt="Integration page picture"
              className={css.integrationPagePicture}
              ref={topRef}
            />
            <div className={css.integrationPagePictureTextContainer}>
              <Text
                className={cn(css.integrationPagePictureText, css.lineOne)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineOne")}
              </Text>
              <Text
                className={cn(css.integrationPagePictureText, css.lineTwo)}
                type="white"
                bold
                secondaryFamily
              >
                {t("events.pictureTextLineTwo")}
              </Text>
            </div>
          </div>
          <div>
            <table className={css.packageTable}>
              <thead className={css.tableHead}>
                <tr className={css.columnTitles}>
                  <th>
                    <Text
                      className={css.flexItem}
                      bold
                      secondaryFamily
                      alignCenter
                      type="white"
                    >
                      {t("integrationPage.columnTitleOne")}
                    </Text>
                  </th>
                  <th>
                    <Text
                      className={css.flexItem}
                      bold
                      secondaryFamily
                      alignCenter
                      type="white"
                    >
                      {t("integrationPage.columnTitleTwo")}
                    </Text>
                  </th>
                  <th>
                    <Text
                      className={css.flexItem}
                      bold
                      secondaryFamily
                      alignCenter
                      type="white"
                    >
                      {t("integrationPage.columnTitleThree")}
                    </Text>
                  </th>
                  <th>
                    <Text
                      className={css.flexItem}
                      bold
                      secondaryFamily
                      alignCenter
                      type="white"
                    >
                      {isMobile
                        ? t("integrationPage.columnTitleFourMobile")
                        : t("integrationPage.columnTitleFour")}
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {packages.map((packageItem, index) => (
                  <tr key={index}>
                    <td className={css.oddColumn}>
                      <Text className={css.columnText} type="white" alignCenter>
                        {packageItem.packageName}
                      </Text>
                    </td>
                    <td className={css.evenColumn}>
                      <Text className={css.columnText} type="white" alignCenter>
                        {packageItem.packagePrice}
                      </Text>
                    </td>
                    <td className={css.oddColumn}>
                      <Text className={css.columnText} type="white" alignCenter>
                        {packageItem.packageMats}
                      </Text>
                    </td>
                    <td className={css.evenColumn}>
                      <Text className={css.columnText} type="white" alignCenter>
                        {packageItem.packagesPlayers}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <Text
                className={css.descriptionTitle}
                bold
                type="white"
                alignCenter
                secondaryFamily
              >
                {t("integrationPage.descriptionTitle")}
              </Text>
              <Text className={css.descriptionText} alignCenter type="white">
                {t(`integrationPage.descriptionText`)}
              </Text>
            </div>
            <Text type="white" bold className={css.dateText}>
              {t(`integrationPage.updateDate.${city}`)}
            </Text>
            <img
              src={gradientBar}
              alt="gradient Bar"
              className={css.gradientBar}
            />
            <div>
              <Text
                className={css.addonsTitle}
                bold
                alignCenter
                type="white"
                secondaryFamily
              >
                {t("integrationPage.addonsTitleIntegration")}
              </Text>
              <Text className={css.addons} alignCenter type="white">
                {t(`integrationPage.addonsIntegration.${city}`)}
              </Text>
            </div>
            <div className={css.buttonSection}>
              <Button
                text={t("events.bookingButton")}
                onClick={() => {
                  navigate(`/${city}/booking`);
                }}
              ></Button>
            </div>
            <img
              src={gradientBar}
              alt="gradient Bar"
              className={css.gradientBar}
            />
          </div>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_EVENTS_RECAPTCHA_KEY}
            container={{
              element: "recaptchaContainer",
              parameters: {
                badge: "bottomleft",
                size: "invisible",
              },
            }}
          >
            <EventsPagesForm
              setIsSubmitLoading={setIsSubmitLoading}
              pageType="Integration"
              city={city}
            ></EventsPagesForm>
          </GoogleReCaptchaProvider>
        </div>
      </div>
      <Footer scrollCallback={footerScrollCallback} />
    </div>
  );
};
