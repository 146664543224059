import React from "react";
import { Text } from "shared/components/Text";
import cn from "classnames";
import { Feature } from "features/events/BirthdayPage/Feature";
import css from "./styles.module.scss";

export const DesktopPackages = ({ packages, addons, t }) => {
  return (
    <table className={css.table}>
      <thead>
        <tr className={css.headerRow}>
          <th>
            <Text
              className={css.columnHeader}
              type="white"
              secondaryFamily
              bold
              alignCenter
            >
              {t("birthdayPage.addonsTitle")}
            </Text>
          </th>
          {packages.map((packageItem, index) => (
            <th key={index}>
              <Text
                type="white"
                className={css.columnHeader}
                secondaryFamily
                bold
                alignCenter
              >
                {packageItem.name}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {addons.map((addon, addonIndex) => (
          <tr key={addonIndex}>
            <td className={css.column1}>
              <Text type="white" className={css.cellText} alignCenter>
                {addon}
              </Text>
            </td>
            {packages.map((packageItem, packageIndex) => (
              <td
                key={packageIndex}
                className={cn(css.cellText, css[`column${packageIndex + 1}`])}
              >
                <Feature feature={packageItem.features[addonIndex]} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className={css.footerRow}>
          <td>
            <Text type="white" className={css.columnFooter}>
              {t("birthdayPage.dayFromMonToThr")}
            </Text>
          </td>
          {packages.map((packageItem, index) => (
            <td key={index}>
              <Text
                type="white"
                className={css.columnFooterCellText}
                bold
                secondaryFamily
                alignCenter
              >
                {packageItem.priceMonToThr}
              </Text>
            </td>
          ))}
        </tr>
        <tr className={css.footerRow}>
          <td>
            <Text type="white" className={css.columnFooter}>
              {t("birthdayPage.dayFromFriToSun")}
            </Text>
          </td>
          {packages.map((packageItem, index) => (
            <td key={index}>
              <Text
                type="white"
                className={css.columnFooterCellText}
                bold
                secondaryFamily
                alignCenter
              >
                {packageItem.priceFriToSun}
              </Text>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
};
