import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import css from "./styles.module.scss";

export const Menu = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const url = useMemo(() => {
    if (searchParams.get("part") === "1") {
      if (searchParams.get("city")) {
        return `/videos/${searchParams.get("city")}-menu-part-1.v3.mp4`;
      }
      return "/videos/menu-part-1.v3.mp4";
    }
    if (searchParams.get("city")) {
      return `/videos/${searchParams.get("city")}-menu-part-2.mp4`;
    }
    return "/videos/menu-part-2.mp4";
  }, []);

  return <video src={url} autoPlay muted loop className={css.Menu} />;
};
