import { Spinner } from "shared/components/Spinner";

import css from "./styles.module.scss";

export const LoadingOverlay = () => {
  return (
    <div className={css.overlay}>
      <Spinner />
    </div>
  );
};
