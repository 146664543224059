import cn from "classnames";

import css from "./styles.module.scss";
import { Text } from "../Text";
import { useRef } from "react";

export const RadioButton = ({ value, name, label, className, onChange }) => {
  const radioButtonRef = useRef(null);

  return (
    <div className={cn(css.RadioButton, className)}>
      <input
        ref={radioButtonRef}
        type="radio"
        value={value}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        className={css.input}
      />
      <button onClick={() => radioButtonRef.current.click()}>
        <Text className={css.label} type="black" alignCenter>
          {label}
        </Text>
      </button>
    </div>
  );
};
