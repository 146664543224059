import cn from "classnames";

import { Text } from "shared/components/Text";

import css from "./styles.module.scss";

export const JobOffer = ({ offer, className, style }) => {
  return (
    // <div className={cn(css.jobOfferContainer, className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(css.jobOffer, css.clipPath, className)}
      style={style}
    >
      <a
        href={offer.link}
        target="_blank"
        rel="noreferrer"
        className={css.jobOffer}
      >
        <polygon points="140,0 280,140 140,280 0,140" fill="#43315b" />
        <foreignObject className={cn(css.jobOffer, className)}>
          <div className={cn(css.jobOffer, css.jobOfferWithPadding)}>
            <Text
              type="white"
              className={css.jobAdditionalInfo}
              secondaryFamily
            >
              {offer.additionalInfo}
            </Text>
            <Text type="pink" className={css.jobTitle} secondaryFamily>
              {offer.name}
            </Text>
            <Text type="white" className={css.jobCity} secondaryFamily>
              {offer.city}
            </Text>
          </div>
        </foreignObject>
      </a>
    </svg>
    // </div>
  );
};
