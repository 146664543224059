export const setupPlan4u = () => {
  (function () {
    window.iFrameResize({ log: true }, "#fBookIt");
  })();
};

export const injectBookeroArenaScript = () => {
  window.bookero_config = {
    ...window.bookero_config,
    custom_config: {
      use_service_id: 41725,
    },
  };

  (function () {
    let script = document.createElement("script");
    script.src = "https://cdn.bookero.pl/plugin/v2/js/bookero-compiled.js";
    document.body.appendChild(script);
  })();
};

export const prettifyMiliseconds = (miliseconds) => {
  let hours = Math.floor(
    (miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((miliseconds % (1000 * 60)) / 1000);

  if (hours < 10) {
    hours = "0" + hours.toString();
  } else {
    hours = hours.toString();
  }

  if (minutes < 10) {
    minutes = "0" + minutes.toString();
  } else {
    minutes = minutes.toString();
  }

  if (seconds < 10) {
    seconds = "0" + seconds.toString();
  } else {
    seconds = seconds.toString();
  }

  return (parseInt(hours) ? hours + ":" : "") + minutes + ":" + seconds;
};

export const splitArrayToChunks = ({ array, chunkSize }) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};
