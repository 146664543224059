import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import { RadioButton } from "shared/components/RadioButton";
import { Spinner } from "../Spinner";

import css from "./styles.module.scss";
import { Checkbox } from "shared/components/Checkbox";
import { updateUserData } from "../formApi";

export const AlreadyFilledStep = ({
  alreadyExistingUserId,
  numberOfSubmits,
  setStep,
}) => {
  const { t } = useTranslation();

  const [newChildren, setNewChildren] = useState();
  const [firstChildrenConsent, setFirstChildrenConsent] = useState(false);
  const [secondChildrenConsent, setSecondChildrenConsent] = useState(false);

  const [connectionError, setConnectionError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setConnectionError("");
    setIsLoading(true);
    try {
      await updateUserData({
        userId: alreadyExistingUserId,
        withChildren: !!newChildren,
        numberOfSubmits: numberOfSubmits + 1,
      });
      setStep("thankYou");
    } catch (e) {
      setConnectionError(t("form.errors.connectionError"));
    }
    setIsLoading(false);
  };

  return (
    <div className={css.AlreadyFilledStep}>
      <Text className={css.prompt} type="pink" secondaryFamily alignCenter>
        {t("form.alreadyFilledPrompt")}
      </Text>
      <Text className={css.prompt} type="lightBlue" secondaryFamily alignCenter>
        {t("form.alreadyFilledLastQuestionPrompt")}
      </Text>
      <div className={css.radioButtonContainer}>
        <div className={css.labelContainer}>
          <Text
            className={css.childrenPrompt}
            type="black"
            alignCenter
            secondaryFamily
          >
            {t("form.alreadyFilledChildrenPrompt")}
          </Text>
        </div>
        <div className={css.innerRadioButtonContainer}>
          <RadioButton
            value="yes"
            name="children"
            label={t("form.yes")}
            className={css.radioButton}
            onChange={() => setNewChildren(true)}
          />
          <RadioButton
            value="no"
            name="children"
            label={t("form.no")}
            className={css.radioButton}
            onChange={() => setNewChildren(false)}
          />
        </div>
      </div>
      <div>
        {newChildren === true && (
          <>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setFirstChildrenConsent((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={firstChildrenConsent}
                    onChange={() => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.childrenPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  {t("form.childrenConsentPrompt")}
                </Text>
              </div>
            </button>
            <button
              className={css.checkboxContainerButton}
              onClick={() => setSecondChildrenConsent((fcc) => !fcc)}
            >
              <div className={css.labelContainer}>
                <div className={css.innerCheckboxContainer}>
                  <Checkbox
                    value={secondChildrenConsent}
                    onChange={() => {}}
                    className={css.checkbox}
                  />
                </div>
                <Text
                  className={css.childrenPrompt}
                  type="black"
                  alignCenter
                  secondaryFamily
                >
                  <Trans
                    i18nKey="form.childrenHealthConsentPrompt"
                    components={[
                      <a
                        className={css.link}
                        href="https://pixel-xl.com/documents/health-contraindications.pdf"
                        target="_blank"
                      />,
                    ]}
                  />
                </Text>
              </div>
            </button>
          </>
        )}
        {(newChildren === false ||
          (firstChildrenConsent && secondChildrenConsent)) && (
          <Button
            text={t("form.submit")}
            className={css.button}
            textClassname={css.buttonText}
            onClick={() => onSubmit()}
          />
        )}
        {isLoading && <Spinner className={css.spinner} />}
        {connectionError && (
          <Text className={css.error} type="red" secondaryFamily alignCenter>
            {connectionError}
          </Text>
        )}
      </div>
    </div>
  );
};
