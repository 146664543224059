import cn from "classnames";

import css from "./styles.module.scss";
import { Text } from "../Text";

export const Checkbox = ({ value, className, onChange, label }) => {
  return (
    <div className={cn(css.Checkbox, className)}>
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        className={css.input}
      />
      <Text className={css.label} type="black" alignCenter>
        {label}
      </Text>
    </div>
  );
};
