import { JobOffer } from "../../JobOffer";
import { Pixel } from "../../Pixel";
import css from "./styles.module.scss";

export const OneOfferView = ({ offer }) => {
  const pixelMultiplier = window.matchMedia("(min-width: 2800px)").matches
    ? window.innerWidth / 1920
    : 1;

  return (
    <div className={css.OneOfferView}>
      <JobOffer offer={offer} />
      <Pixel
        width={40 * pixelMultiplier}
        height={40 * pixelMultiplier}
        left={`calc(50% - ${225 * pixelMultiplier}px)`}
        bottom={`${60 * pixelMultiplier}px`}
      />
      <Pixel
        width={70 * pixelMultiplier}
        height={70 * pixelMultiplier}
        left={`calc(50% + ${150 * pixelMultiplier}px)`}
        bottom={`${120 * pixelMultiplier}px`}
      />
      <Pixel
        width={50 * pixelMultiplier}
        height={50 * pixelMultiplier}
        left={`calc(50% + ${300 * pixelMultiplier}px)`}
        bottom={`${270 * pixelMultiplier}px`}
      />
      <Pixel
        width={30 * pixelMultiplier}
        height={30 * pixelMultiplier}
        left={`calc(50% - ${330 * pixelMultiplier}px)`}
        bottom={`${265 * pixelMultiplier}px`}
      />
    </div>
  );
};
