import ky from "ky";

const requestClient = ky.create({
  prefixUrl:
    "https://europe-central2-pixelxl-database-dev.cloudfunctions.net/sign-up-for-events",
});

export const signUpForEvents = ({ name, surname, phone, pageType, city, email,  token }) => {
  return requestClient.post("sign-up", {
    body: JSON.stringify({
      name,
      surname,
      phone,
      email,
      recaptchaToken: token,
      eventType: pageType,
      city: city
    }),
  });
};
