import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/Button";
import { Text } from "shared/components/Text";
import { TextInput } from "shared/components/TextInput";
import { checkIfUsersEmailExists } from "../formApi";
import { Spinner } from "../Spinner";

import css from "./styles.module.scss";

const emailPattern =
  /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const EmailStep = ({
  setStep,
  email,
  setEmail,
  setAlreadyExistingUserId,
  setNumberOfSubmits,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const city = searchParams.get("city") || "wroclaw";

  const [emailError, setEmailError] = useState("");
  const [connectionError, setConnectionError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ email }) => {
    setConnectionError("");

    const emailTrimmed = email.trim();
    if (!emailTrimmed) {
      setEmailError(t("form.errors.emptyEmail"));
      return;
    } else if (!emailPattern.test(emailTrimmed)) {
      setEmailError(t("form.errors.incorrectEmail"));
      return;
    } else if (emailTrimmed.length > 64) {
      setEmailError(t("form.errors.emailTooLong"));
      return;
    }
    setEmailError("");

    setIsLoading(true);
    try {
      const response = await checkIfUsersEmailExists({
        email: emailTrimmed,
        city,
      });
      const userData = JSON.parse(response.body);
      setAlreadyExistingUserId(userData.userId);
      setNumberOfSubmits(userData.numberOfSubmits);

      setStep("alreadyFilled");
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setStep("newUser");
      } else {
        setConnectionError(t("form.errors.connectionError"));
      }
    }
    setIsLoading(false);
  };

  return (
    <form className={css.EmailStep} onSubmit={(e) => e.preventDefault()}>
      <Text className={css.helloPrompt} type="pink" secondaryFamily>
        {t("form.helloPrompt")}
      </Text>
      <Text className={css.prompt} type="black" secondaryFamily alignCenter>
        {t("form.emailPrompt")}
      </Text>
      <TextInput
        value={email}
        setValue={setEmail}
        label={t("form.fields.email")}
        error={emailError}
        type="email"
        autoComplete="email"
        mode="form"
      />
      <Button
        text={t("form.nextButtonText")}
        className={css.button}
        textClassname={css.buttonText}
        onClick={() => onSubmit({ email })}
        disabled={isLoading}
      />
      {isLoading && <Spinner className={css.spinner} />}
      {connectionError && (
        <Text className={css.error} type="red" secondaryFamily alignCenter>
          {connectionError}
        </Text>
      )}
    </form>
  );
};
